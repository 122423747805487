import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter, Route, Switch } from "react-router-dom";

import { AppProvider } from "./contexts/app-context";

import "./scss/style.scss";

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./pages/Login/Login"));

const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<AppProvider>
				<HashRouter>
					<React.Suspense fallback={loading}>
						<Switch>
							<Route exact path="/login" component={Login} />

							<Route path="/" component={TheLayout} />
						</Switch>
					</React.Suspense>
				</HashRouter>
			</AppProvider>
		</QueryClientProvider>
	);
}

export default App;
