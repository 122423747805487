import axios, { AxiosError, AxiosInstance } from "axios";
import React, { useMemo } from "react";

type Action =
	| { type: "setSidebarShow"; payload: string | boolean }
	| { type: "setAuthenticated"; payload: boolean };
type Dispatch = (action: Action) => void;
type State = {
	sidebarShow: string | boolean;
	authenticated: boolean;
};
type ProviderProps = { children: React.ReactNode };

const API_URL = process.env.REACT_APP_API_URL;

const AppStateContext = React.createContext<
	| {
			state: State;
			dispatch: Dispatch;
			axios: AxiosInstance;
	  }
	| undefined
>(undefined);

function stateReducer(state: State, action: Action) {
	switch (action.type) {
		case "setSidebarShow": {
			return { ...state, sidebarShow: action.payload };
		}

		case "setAuthenticated":
			return { ...state, authenticated: action.payload };

		default: {
			throw new Error(`Unhandled action type`);
		}
	}
}

function AppProvider({ children }: ProviderProps) {
	const [state, dispatch] = React.useReducer(stateReducer, {
		sidebarShow: "responsive",
		authenticated: localStorage.getItem("token") ? true : false,
	});

	const instance = useMemo(() => {
		const instance = axios.create({
			baseURL: API_URL,
			headers: {
				"Content-Type": "application/json",
			},
		});

		instance.interceptors.request.use((config) => {
			// Read token for anywhere, in this case directly from localStorage
			const token = localStorage.getItem("token");

			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}

			return config;
		});

		// 401 listener
		instance.interceptors.response.use(
			(response) => response,
			(err: AxiosError) => {
				if (err.response?.status === 401) {
					localStorage.removeItem("token");

					dispatch({ type: "setAuthenticated", payload: false });
				}

				return Promise.reject(err);
			}
		);

		return instance;
	}, []);

	const value = useMemo(
		() => ({
			state,
			dispatch,
			axios: instance,
		}),
		[state, dispatch, instance]
	);

	return (
		<AppStateContext.Provider value={value}>
			{children}
		</AppStateContext.Provider>
	);
}

function useAppState() {
	const context = React.useContext(AppStateContext);

	if (context === undefined) {
		throw new Error("AppStateContext is undefined");
	}
	return context;
}

export { AppProvider, useAppState };
